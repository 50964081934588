
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Folder } from '@/lib/dnote'

const dnote = namespace('dnote')

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'note-form',
})
export default class NoteForm extends Props {
  title = ''
  body = ''

  @dnote.State
  currentFolder!: Folder | null

  /* eslint-disable no-unused-vars */
  @dnote.Action
  createNote!: (payload: {title: string, body: string, folder?: string}) => void

  @dnote.Mutation
  setShowNoteNewDialog!: (show: boolean) => void
  /* eslint-enable no-unused-vars */

  async onSave () {
    const payload: {
      title: string,
      body: string,
      folder?: string
    } = {
      title: this.title,
      body: this.body,
    }

    if (this.currentFolder) {
      payload.folder = this.currentFolder._id
    }

    this.createNote(payload)
    this.setShowNoteNewDialog(false)
  }
}
