import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'

Vue.config.productionTip = false

const app = new Vue({
  store,
  vuetify,
  render: h => h(App),
})
app.$mount('#app')
