
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators'

const dnote = namespace('dnote')
import DNoteModule from '@/store/modules/dnote'
import NoteEditForm from '@/components/NoteEditForm.vue'

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'note-edit-dialog',
  components: {
    NoteEditForm,
  },
})
export default class NoteEditDialog extends Props {
  get showNoteEditDialog(): boolean {
    const module = getModule(DNoteModule, this.$store);
    return module.showNoteEditDialog
  }

  set showNoteEditDialog(show: boolean) {
    const module = getModule(DNoteModule, this.$store);
    module.setShowNoteEditDialog(show)
  }

  /* eslint-disable no-unused-vars */
  @dnote.Mutation
  setShowNoteEditDialog!: (show: boolean) => void
  /* eslint-enable no-unused-vars */

  save() {
    const form = this.$refs.form as NoteEditForm
    if (form) {
      form.onSave()
    }
  }
}
