
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Folder, Note } from '@/lib/dnote'

const dnote = namespace('dnote')

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'note-form',
})
export default class NoteForm extends Props {
  @dnote.State
  currentNote!: Note | null

  @dnote.State
  currentFolder!: Folder | null

  /* eslint-disable no-unused-vars */
  @dnote.Action
  updateNote!: (payload: {id: string, rev?: string, title: string, body: string, folder?: string}) => void

  @dnote.Mutation
  setShowNoteEditDialog!: (show: boolean) => void
  /* eslint-enable no-unused-vars */

  async onSave () {
    if (!this.currentNote) {
      return
    }

    const payload: {
      id: string,
      rev?: string,
      title: string,
      body: string,
      folder?: string
    } = {
      id: this.currentNote._id,
      rev: this.currentNote._rev,
      title: this.currentNote.title,
      body: this.currentNote.body,
    }

    if (this.currentFolder) {
      payload.folder = this.currentFolder._id
    }

    this.updateNote(payload)
    this.setShowNoteEditDialog(false)
  }
}
