
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Folder } from '@/lib/dnote'

const dnote = namespace('dnote')

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'folder-new-form',
})
export default class FolderNewForm extends Props {
  name = ''

  @dnote.State
  currentFolder!: Folder | null

  /* eslint-disable no-unused-vars */
  @dnote.Action
  createFolder!: (payload: {name: string, folder?: string}) => void

  @dnote.Mutation
  setShowFolderNewDialog!: (show: boolean) => void
  /* eslint-enable no-unused-vars */

  async onSave () {
    const payload: {
      name: string,
      folder?: string,
    } = {
      name: this.name,
    }

    if (this.currentFolder) {
      payload.folder = this.currentFolder._id
    }

    this.createFolder(payload)
    this.setShowFolderNewDialog(false)
  }
}
