
import Vue from 'vue'
import Component from 'vue-class-component'
import { marked } from 'marked'

const Props = Vue.extend({
  props: {
    body: String,
  }
})

@Component({
  name: 'markdown-preview',
})
export default class NoteActions extends Props {
  get compiledMarkdown() {
    return marked(this.body, { sanitize: true })
  }
}
