
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators'
import { Folder } from '@/lib/dnote'

const dnote = namespace('dnote')
import DNoteModule from '@/store/modules/dnote'

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'folder-edit-form',
})
export default class FolderEditForm extends Props {
  name = ''

  get currentFolder(): Folder | null {
    const module = getModule(DNoteModule, this.$store);
    return module.currentFolder
  }

  @dnote.State
  editFolder!: Folder | null

  /* eslint-disable no-unused-vars */
  @dnote.Action
  updateFolder!: (payload: {id: string, rev?: string, name: string, folder?: string}) => void

  @dnote.Mutation
  setShowFolderEditDialog!: (show: boolean) => void
  /* eslint-enable no-unused-vars */

  async onSave () {
    if (!this.editFolder) {
      return
    }

    const payload: {
      id: string,
      rev?: string,
      name: string,
      folder?: string,
    } = {
      id: this.editFolder._id,
      rev: this.editFolder._rev,
      name: this.editFolder.name,
    }

    if (this.currentFolder) {
      payload.folder = this.currentFolder._id
    }

    this.updateFolder(payload)
    this.setShowFolderEditDialog(false)
  }
}
