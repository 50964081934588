
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators'
import { Folder, Note } from '@/lib/dnote'
import { formatTimestamp } from '@/lib'

import FolderActions from '@/components/FolderActions.vue'
import NoteActions from '@/components/NoteActions.vue'

const dnote = namespace('dnote')
import DNoteModule from '@/store/modules/dnote'

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'note-list',
  components: {
    FolderActions,
    NoteActions,
  },
})
export default class NoteList extends Props {
  get folders(): {[key: string]: Folder} {
    const module = getModule(DNoteModule, this.$store);
    return module.folders
  }

  get notes(): {[key: string]: Note} {
    const module = getModule(DNoteModule, this.$store);
    return module.notes
  }

  get folderHierarchy(): {id: string, name: string}[] {
    const module = getModule(DNoteModule, this.$store);
    return module.folderHierarchy
  }

  get foldersInCurrentFolder(): Folder[] {
    const module = getModule(DNoteModule, this.$store);
    return module.foldersInCurrentFolder
  }

  get notesInCurrentFolder(): Note[] {
    const module = getModule(DNoteModule, this.$store);
    return module.notesInCurrentFolder
  }

  /* eslint-disable no-unused-vars */
  @dnote.Mutation
  setCurrentFolder!: (folder: Folder | null) => void

  @dnote.Mutation
  setCurrentNote!: (note: Note | null) => void

  @dnote.Mutation
  setShowNoteEditDialog!: (show: boolean) => void

  @dnote.Mutation
  setShowNotePreviewDialog!: (show: boolean) => void

  @dnote.Action
  loadFolders!: () => void

  @dnote.Action
  loadNotes!: () => void
  /* eslint-enable no-unused-vars */

  async created() {
    this.loadFolders()
    this.loadNotes()
  }

  formatTimestamp(t: number): string {
    return formatTimestamp(t)
  }

  onBreadcrumbClick(folder: {id: string, name: string}) {
    this.setCurrentFolder(this.folders[folder.id])
  }

  onFolderClick(folder: Folder) {
    this.setCurrentFolder(folder)
  }

  onNoteClick(note: Note) {
    this.setCurrentNote(note)
    this.setShowNotePreviewDialog(true)
  }
}
