export interface Tag {
  _id: string
  _rev?: string
  type: 'tag'
  name: string
  parentID: string | null
  children: string[]
}

export const EMPTY_TAG: Tag = {
  _id: '',
  type: 'tag',
  name: '',
  parentID: null,
  children: [],
}

export function formatTimestamp(previous: number) {
  const current = Date.now()
  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30
  const msPerYear = msPerDay * 365
  const elapsed = current - previous

  if (elapsed < msPerMinute) {
      return Math.round(elapsed/1000) + ' seconds ago'
  } else if (elapsed < msPerHour) {
      return Math.round(elapsed/msPerMinute) + ' minutes ago'
  } else if (elapsed < msPerDay ) {
      return Math.round(elapsed/msPerHour ) + ' hours ago'
  } else if (elapsed < msPerMonth) {
      return Math.round(elapsed/msPerDay) + ' days ago'
  } else if (elapsed < msPerYear) {
      return Math.round(elapsed/msPerMonth) + ' months ago'
  }

  return Math.round(elapsed/msPerYear ) + ' years ago'
}

export function arrayFromMap(m: Record<string, unknown>): unknown[] {
  const a = []

  for (const key of Object.keys(m)) {
    const item = m[key]
    a.push(item)
  }

  return a
}

export function stringToHex(s: string): string {
  let result = '';

  for (let i = 0; i < s.length; i++) {
    const hex = s.charCodeAt(i).toString(16)
    result += hex
  }

  return result
}
