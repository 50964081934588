import Vue from 'vue'
import { VuexModule, Module, Mutation, Action, config } from 'vuex-module-decorators'
import { v4 as uuidv4 } from 'uuid'

import { localDB } from '@/lib/common'
import { Tag, EMPTY_TAG } from '@/lib'
import { Note, Folder } from '@/lib/dnote'

// Set rawError to true by default on all @Action decorators
config.rawError = true

@Module({ name: 'dnote', namespaced: true })
class DNoteModule extends VuexModule {
  ready: boolean = false
  syncing = false

  // Notes
  notes: {[key: string]: Note} = {}
  currentNote: Note | null = null
  showNoteNewDialog = false
  showNoteEditDialog = false
  showNotePreviewDialog = false

  // Folder
  folders: {[key: string]: Folder} = {}
  currentFolder: Folder | null = null
  editFolder: Folder | null = null
  showFolderNewDialog = false
  showFolderEditDialog = false

  // Snackbars
  infoSnackbar = false
  infoSnackbarText = ''
  errorSnackbar = false
  errorSnackbarText = ''

  // Filter dialog
  bShowFilterDialog = false

  @Mutation
  setReady(ready: boolean): void {
    this.ready = ready
  }

  @Mutation
  showInfo(msg: string): void {
    this.infoSnackbar = true
    this.infoSnackbarText = msg
  }

  @Mutation
  showError(msg: string): void {
    this.errorSnackbar = true
    this.errorSnackbarText = msg
  }

  @Mutation
  setInfoSnackbar(value: boolean): void {
    this.infoSnackbar = value
  }

  @Mutation
  setErrorSnackbar(value: boolean): void {
    this.errorSnackbar = value
  }

  @Mutation
  reloadSavedNotes(docs: any[]): void {
    for (const key in this.notes) {
      Vue.delete(this.notes, key)
    }

    for (const doc of docs) {
      Vue.set(this.notes, doc._id, doc)
    }
  }

  /*********
   * Notes *
   *********/

  @Action
  async loadNotes(): Promise<void> {
    try {
      const res = await localDB.find({
        selector: {
          type: {$eq: 'note'},
        },
      })

      this.context.commit('refreshNotes', res['docs'])
    } catch (e) {
      this.context.commit('showError', e)
    }
  }

  @Mutation
  refreshNotes(docs: any[]): void {
    for (const key in this.notes) {
      Vue.delete(this.notes, key)
    }

    for (const doc of docs) {
      Vue.set(this.notes, doc._id, doc)
    }
  }

  @Action
  async createNote(payload: {title: string, body: string, folder?: string}) {
    const now = Date.now()
    const doc: Note = {
      '_id': uuidv4(),
      'type': 'note',
      'title': payload.title,
      'body': payload.body,
      'createdAt': now,
      'updatedAt': now,
    }

    if (payload.folder) {
      doc['folder'] = payload.folder
    }

    try {
      await localDB.put(doc)
      const updatedDoc = await localDB.get(doc._id)
      this.context.commit('updateLocalData', updatedDoc)
    } catch (e) {
      this.context.commit('showError', e)
    }
  }

  @Action
  async updateNote(payload: {id: string, rev?: string, title: string, body: string, folder?: string}) {
    const now = Date.now()
    const doc: Note = {
      '_id': payload.id,
      '_rev': payload.rev,
      'type': 'note',
      'title': payload.title,
      'body': payload.body,
      'updatedAt': now,
    }

    if (payload.folder) {
      doc['folder'] = payload.folder
    }

    try {
      await localDB.put(doc)
      const updatedDoc = await localDB.get(doc._id)
      this.context.commit('updateLocalData', updatedDoc)
    } catch (e) {
      this.context.commit('showError', e)
    }
  }

  @Action
  async removeNote(payload: {id: string}) {
    const doc = await localDB.get(payload.id)
    try {
      await localDB.remove(doc)
      this.context.commit('removeLocalData', doc)
    } catch (e) {
      this.context.commit('showError', e)
    }
  }

  @Mutation
  setCurrentNote(note: Note | null): void {
    this.currentNote = note
  }

  @Mutation
  setShowNoteNewDialog(show: boolean): void {
    this.showNoteNewDialog = show
  }

  @Mutation
  setShowNoteEditDialog(show: boolean): void {
    this.showNoteEditDialog = show
  }

  @Mutation
  setShowNotePreviewDialog(show: boolean): void {
    this.showNotePreviewDialog = show
  }

  get notesInCurrentFolder(): Note[] {
    return Object
        .entries(this.notes)
        .filter(([id, note]) => this.currentFolder ? note.folder === this.currentFolder._id : !Object.prototype.hasOwnProperty.call(note, 'folder'))
        .map(([id, note]) => note)
  }

  /**********
   * Folder *
   **********/

  @Action
  async loadFolders(): Promise<void> {
    try {
      const res = await localDB.find({
        selector: {
          type: {$eq: 'folder'},
        },
      })

      this.context.commit('refreshFolders', res['docs'])
    } catch (e) {
      this.context.commit('showError', e)
    }
  }

  @Mutation
  refreshFolders(docs: any[]): void {
    for (const key in this.folders) {
      Vue.delete(this.folders, key)
    }

    for (const doc of docs) {
      Vue.set(this.folders, doc._id, doc)
    }
  }

  @Action
  async createFolder(payload: {name: string, folder?: string}) {
    const now = Date.now()
    const doc: Folder = {
      '_id': uuidv4(),
      'type': 'folder',
      'name': payload.name,
      'createdAt': now,
      'updatedAt': now,
    }

    if (payload.folder) {
      doc['folder'] = payload.folder
    }

    try {
      await localDB.put(doc)
      const updatedDoc = await localDB.get(doc._id)
      this.context.commit('updateLocalData', updatedDoc)
    } catch (e) {
      this.context.commit('showError', e)
    }
  }

  @Action
  async updateFolder(payload: {id: string, rev?: string, name: string, folder?: string}) {
    const now = Date.now()
    const doc: Folder = {
      '_id': payload.id,
      '_rev': payload.rev,
      'type': 'folder',
      'name': payload.name,
      'updatedAt': now,
    }

    if (payload.folder) {
      doc['folder'] = payload.folder
    }

    try {
      await localDB.put(doc)
      const updatedDoc = await localDB.get(doc._id)
      this.context.commit('updateLocalData', updatedDoc)
    } catch (e) {
      this.context.commit('showError', e)
    }
  }

  @Action
  async removeFolder(payload: {id: string}) {
    const doc = await localDB.get(payload.id)
    try {
      const res = await localDB.find({
        selector: {
          folder: {$eq: payload.id},
        },
      })

      // Remove notes and folders inside the folder
      for (const _doc of res['docs']) {
       const doc = _doc as any
        if (doc['type'] === 'note') {
          this.context.dispatch('removeNote', {id: doc['_id']})
        } else if (doc['type'] === 'folder') {
          this.context.dispatch('removeFolder', {id: doc['_id']})
        } else {
          console.warn('Unknown document type:', doc['type'])
        }
      }

      await localDB.remove(doc)
      this.context.commit('removeLocalData', doc)
    } catch (e) {
      this.context.commit('showError', e)
    }
  }

  @Mutation
  setCurrentFolder(folder: Folder | null): void {
    this.currentFolder = folder
  }

  @Mutation
  setEditFolder(folder: Folder | null): void {
    this.editFolder = folder
  }

  @Mutation
  setShowFolderNewDialog(show: boolean): void {
    this.showFolderNewDialog = show
  }

  @Mutation
  setShowFolderEditDialog(show: boolean): void {
    this.showFolderEditDialog = show
  }

  get foldersInCurrentFolder(): Folder[] {
    return Object.entries(this.folders)
        .filter(([id, folder]) => this.currentFolder ? folder.folder === this.currentFolder._id : !Object.prototype.hasOwnProperty.call(folder, 'folder'))
        .map(([id, folder]) => folder)
  }

  get folderHierarchy(): {id: string, name: string}[] {
    const hierarchy: {id: string, name: string}[] = [{id: 'root', name: 'Home'}]
    if (!this.currentFolder) {
      return hierarchy
    }

    hierarchy.push({id: this.currentFolder._id, name: this.currentFolder.name})
    let folder = this.currentFolder
    while (folder.folder) {
      folder = this.folders[folder.folder]
      hierarchy.push({id: folder._id, name: folder.name})
    }
    return hierarchy
  }

  /*********
   * Misc. *
   *********/

  @Action
  showNote(note: Note) {
    this.context.commit('setCurrentNote', note)
    this.context.commit('setNoteDialog', true)
  }

  @Action
  closeNote() {
    this.context.commit('setCurrentNote', null)
    this.context.commit('setSelectedTags', [])
    this.context.commit('setNoteDialog', false)
  }

  @Mutation
  setSyncing(payload: {active: boolean}) {
    this.syncing = payload.active
  }

  @Mutation
  updateLocalData(doc: any) {
    const typ = doc['type']
    if (typ === 'note') {
      Vue.set(this.notes, doc._id, doc)
    } else if (typ === 'folder') {
      Vue.set(this.folders, doc._id, doc)
    } else {
      console.error('Unknown type: ' + typ)
    }
  }

  @Mutation
  removeLocalData(doc: any) {
    let typ = ''

    if (doc._id in this.notes) {
      typ = 'note'
    } else if (doc._id in this.folders) {
      typ = 'folder'
    } else {
      console.error('Unknown type for document: ' + doc._id)
      return
    }

    if (typ === 'note') {
      Vue.delete(this.notes, doc._id)
    } else if (typ === 'folder') {
      Vue.delete(this.folders, doc._id)
    }
  }

  @Mutation
  removeDocument(doc: any) {
    let typ = ''

    if (doc._id in this.notes) {
      typ = 'note'
    } else if (doc._id in this.folders) {
      typ = 'folder'
    } else {
      console.error ('Unknown type for document: ' + doc._id)
      return
    }

    if (typ === 'note') {
      Vue.delete(this.notes, doc._id)
    } else if (typ === 'folder') {
      Vue.delete(this.folders, doc._id)
    }
  }

  get localDB(): PouchDB.Database {
    return localDB
  }
}
export default DNoteModule
