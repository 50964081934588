import PouchDB from 'pouchdb'
import PouchDBFind from 'pouchdb-find'
import PouchDBAuthentication from 'pouchdb-authentication'

import { IS_PRODUCTION } from '@/common'
import { stringToHex } from '@/lib'

PouchDB.plugin(PouchDBFind)
PouchDB.plugin(PouchDBAuthentication)
const POUCHDB_NAME = IS_PRODUCTION ? 'dnote-production' : 'dnote-development'
export const localDB: PouchDB.Database = new PouchDB(POUCHDB_NAME)
