
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators'

const dnote = namespace('dnote')
import DNoteModule from '@/store/modules/dnote'
import FolderNewForm from '@/components/FolderNewForm.vue'

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'folder-new-dialog',
  components: {
    FolderNewForm,
  },
})
export default class FolderNewDialog extends Props {
  get showFolderNewDialog(): boolean {
    const module = getModule(DNoteModule, this.$store);
    return module.showFolderNewDialog
  }

  set showFolderNewDialog(show: boolean) {
    const module = getModule(DNoteModule, this.$store);
    module.setShowFolderNewDialog(show)
  }

  /* eslint-disable no-unused-vars */
  @dnote.Mutation
  setShowFolderNewDialog!: (show: boolean) => void
  /* eslint-enable no-unused-vars */

  save() {
    const form = this.$refs.form as FolderNewForm
    if (form) {
      form.onSave()
    }
  }
}
